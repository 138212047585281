import { Router } from '@reach/router';
import React from 'react';

import DealerOrderDetailPage from '../../components/dealer-orders/DealerOrderDetailPage';

const DealerOrderDetail = () => {
  return (
    <Router basepath="/dealer-orders">
      <DealerOrderDetailPage path="/:handle" />
    </Router>
  );
};

export default DealerOrderDetail;
